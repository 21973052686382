*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

:root {
	--succes-color: #2ecc71;
	--error-color: #f02314;
	--disabled-color: #6a6a6a;
	--pagar-color: #3d963a;
	--editar-color: #ff8800;
	--modificar-color: #ff5515;
	--finalizar-color: #02911f;
}

/* custom scrollbar */
::-webkit-scrollbar {
	width: 24px;
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: none;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #cdcdcd;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}

@font-face {
	font-family: MyriadPro-Bold;
	font-display: swap;
	src: url("./fonts/MyriadPro-Bold.otf");
}

@font-face {
	font-family: MyriadPro-BoldIt;
	font-display: swap;
	src: url("./fonts/MyriadPro-BoldIt.otf");
}

@font-face {
	font-family: MyriadPro-Regular;
	font-display: swap;
	src: url("./fonts/MyriadPro-Regular.otf");
}

@font-face {
	font-family: MyriadPro-Semibold;
	font-display: swap;
	src: url("./fonts/MyriadPro-Semibold.otf");
}

@font-face {
	font-family: NewZurica;
	font-display: swap;
	src: url("./fonts/NewZurica.ttf");
}

html * {
	font-family: MyriadPro-Regular;
}

body,
html {
	height: 100vh;
	width: 100%;
	overflow: hidden;
	min-width: 580px;
}

form {
	width: 100%;
	height: 100%;
}

textarea {
	resize: none;
}

input {
	outline: none;
}

table {
	border-collapse: collapse;
}

table td {
	padding: 0 5px;
}

#root {
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;
	scrollbar-width: thin;
	height: 100%;
	width: 100%;
}

#pvt {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;
	scrollbar-width: thin;
}

/* -------------------------------------------------------- ||
||															||
||						  IMPORTS					        ||
||															||
|| -------------------------------------------------------- */

@import './css/tables.css';
