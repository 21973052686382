.table-result {
	width: 100%;
	background: #fff;
	text-align: center;
	border-collapse: collapse;

	thead {
		background-color: #007391;
	}

	thead tr {
		height: 30px;
		background: #007391 0% 0% no-repeat padding-box;
	}

	thead tr th {
		font-family: MyriadPro-Regular;
		font-weight: normal;
		font-size: 1.2rem;
		color: #fff;
	}

	thead tr th:first-child {
		border-top-left-radius: 10px;
	}

	thead tr th:last-child {
		border-top-right-radius: 10px;
	}

	tr:nth-child(even) {
		background: #e5f7fc 0% 0% no-repeat padding-box;
	}

	tr:last-child:hover {
		background-color: #003948;
	}

	td {
		white-space: nowrap;
		font-family: MyriadPro-Regular;
		font-size: 1.2rem;
		letter-spacing: 0px;

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	tbody tr {
		height: 30px;
		color: #003948;
	}

	tbody tr:hover {
		background-color: #14a0c3;
	}

	tbody tr:last-child {
		height: 32px;
		color: #fff;
		background-color: #003948;
	}

	tbody tr:last-child td:first-child {
		text-align: center;
		border-bottom-left-radius: 10px;
	}

	tbody tr:last-child td:last-child {
		border-bottom-right-radius: 10px;
	}
}
